<template>
  <!-- 库存调整单明细 -->
  <div class="check-detail">
    <StoreDetail :billType="billType" :uuid="uuid" ref="storeDetail">
      <!-- 插槽 -->
      <!-- <template #selectOneArea="scoped">
        <div class="searchItem">
          <el-form-item label="仓库" prop="storeId">
            <SelectFrame
              ref="selectStore"
              v-model="scoped.form.storeName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'storeId', 'form')"
              @clickAdd="OpenStoreAdd = true"
              @click="OpenStore = true"
              :normalizer="scoped.normalizerStore"
              size="mini"
            ></SelectFrame>
          </el-form-item>
        </div>
      </template> -->
      <template #selectTwoArea="scoped">
        <div class="searchItem">
          <el-form-item label="调整方式" prop="billPostType">
            <el-select
              v-model="scoped.form.billPostType"
              placeholder="请选择调整方式"
              clearable
              :disabled="scoped.disabled"
              size="mini"
            >
              <el-option
                v-for="dict in dict.type.in_out_store"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="searchItem div-number-item">
          <el-form-item label="调整原因" prop="scrapTypeId">
            <el-select
              v-model="scoped.form.scrapTypeId"
              placeholder="请选择调整原因"
              clearable
              :disabled="scoped.disabled"
              size="mini"
            >
              <el-option
                v-for="dict in dict.type.adjust_reason"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import StoreDetail from "@/views/components/bill/storeDetail.vue";
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
export default {
  name: "storeAdjustDetail",
  dicts: ["in_out_store", "adjust_reason"],
  components: { StoreDetail, selectStore, storeAdd },
  data() {
    return {
      billType: "130102",
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      uuid:'0b016f2b-0fba-74cd-f894-61739f9beff6'
    };
  },
  methods: {
    //调用storeDetail组件的方法  获取选中的仓库/获取新增仓库
    getStore(event) {
      this.$refs.storeDetail.getStore(event, "form", "storeId", "selectStore", true);
    },
  },
};
</script>
