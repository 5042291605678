var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-detail" },
    [
      _c("StoreDetail", {
        ref: "storeDetail",
        attrs: {
          billType: _vm.billType,
          uuid: _vm.uuid,
          tableMiddleSpecialCount: 2,
          importInfo: _vm.importInfo,
        },
        scopedSlots: _vm._u([
          {
            key: "selectOneArea",
            fn: function (scoped) {
              return [
                _c(
                  "div",
                  { staticClass: "searchItem div-number-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点名称", prop: "checkName" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: "", placeholder: "" },
                          model: {
                            value: scoped.form.checkName,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "checkName", $$v)
                            },
                            expression: "scoped.form.checkName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点仓库", prop: "storeId" } },
                      [
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: "",
                            option: _vm.$select({ key: "listInventory" })
                              .option,
                          },
                          model: {
                            value: scoped.form.storeId,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "storeId", $$v)
                            },
                            expression: "scoped.form.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "tableMiddleSpecial",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "账面数",
                    align: "center",
                    prop: "basUnitAccountQty",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "基本单位盘点数",
                    align: "center",
                    prop: "basUnitQty",
                    "show-overflow-tooltip": "",
                    width: "120",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selectStore", {
        attrs: { OpenStore: _vm.OpenStore },
        on: {
          "update:OpenStore": function ($event) {
            _vm.OpenStore = $event
          },
          "update:open-store": function ($event) {
            _vm.OpenStore = $event
          },
          getStore: _vm.getStore,
        },
      }),
      _c("storeAdd", {
        attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
        on: {
          "update:OpenStoreAdd": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          "update:open-store-add": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          getStore: _vm.getStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }