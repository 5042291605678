<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-if="showList" v-model="options" @handleEvent="handleEvent" />
    <TablePage ref="tablePage" v-if="!showList" v-model="optionsDetail" @handleEvent="handleEvent" />
    <StockQueryDetail
      :openStockQuery.sync="openStockQuery"
      :goodsDetailData="goodsDetailData"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { storeInventoryGoodsList, storeInventoryGoodsDetail } from "@/api/storeReport"; //接口api
// import StockQueryDetail from "./components/stockQueryDetail.vue"; //商品库存详情明细对话框组件
export default {
  name: "jxcList",
  components: { TablePage },
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'10a65ba0-229d-7555-944f-40f02c44396f',
        body: {},
        title: "商品库存查询",
        tableTitle: "商品库存查询",
        rowKey: "billId",
        exportOption: {
          show: true,
          exportApi: 'exportGoodsPurSaleStockList',
          exportName: '商品进销存一览表',
          timeout: 60*5*1000
        },
        search: [
          this.$select({
            key: "listCategory",
            option: { filter: "categoryIds", option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { filter: "goodsIds", option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { filter: "storeIds", option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: storeInventoryGoodsList,
        columns: [
          { label: "仓库", prop: "storeName", minWidth: 120, fixed: true },
          {
            label: "商品编码",
            prop: "goodsNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "oepnDetail",
          },
          { label: "商品名称", prop: "goodsName", minWidth: 100 },
          { label: "条码", prop: "barcode", minWidth: 120 },
          { label: "商品类别", prop: "categoryName", minWidth: 100 },
          { label: "批号", prop: "batchNo", minWidth: 80 },
          { label: "规格", prop: "goodsSpec", minWidth: 80 },
          { label: "基础单位", prop: "unitName", minWidth: 80 },
          { label: "期初数", prop: "firstQty", minWidth: 80 },
          { label: "期初金额", prop: "firstMoney", minWidth: 100 },
          { label: "入库数", prop: "inQty", minWidth: 80 },
          { label: "入库金额", prop: "inMoney", minWidth: 100 },
          { label: "出库数", prop: "outQty", minWidth: 80 },
          { label: "出库金额", prop: "outMoney", minWidth: 100 },
          { label: "期末数", prop: "finalQty", minWidth: 80 },
          { label: "期末金额", prop: "finalMoney", minWidth: 100 },
          {
            label: "入库",
            prop: "billDate",
            children: [
              { label: "采购数", prop: "purQty", minWidth: 100 },
              { label: "采购金额", prop: "purMoney", minWidth: 100 },
              { label: "生产数", prop: "produceInQty", minWidth: 100 },
              { label: "生产金额", prop: "produceInMoney", minWidth: 100 },
              { label: "调入数", prop: "moveInQty", minWidth: 100 },
              { label: "调入金额", prop: "moveInMoney", minWidth: 100 },
              { label: "库存调整入库数", prop: "adjustInQty", minWidth: 120 },
              { label: "库存调整入库金额", prop: "adjustInMoney", minWidth: 130 },
              { label: "销售退货数", prop: "saleBackQty", minWidth: 100 },
              { label: "销售退货金额", prop: "saleBackMoney", minWidth: 100 },
              { label: "盘盈数", prop: "fullQty", minWidth: 100 },
              { label: "盘盈金额", prop: "fullMoney", minWidth: 100 },
              { label: "生产退料数", prop: "backMaterialQty", minWidth: 100 },
              { label: "生产退料金额", prop: "backMaterialMoney", minWidth: 100 },
            ],
          },
          {
            label: "出库",
            prop: "billDate",
            children: [
              { label: "销售数", prop: "saleQty", minWidth: 100 },
              { label: "销售金额", prop: "saleMoney", minWidth: 100 },
              { label: "生产领料数", prop: "leadMaterialQty", minWidth: 100 },
              { label: "生产领料金额", prop: "leadMaterialMoney", minWidth: 100 },
              { label: "调出数", prop: "moveOutQty", minWidth: 100 },
              { label: "调出金额", prop: "moveOutMoney", minWidth: 100 },
              { label: "库存调整出库数", prop: "adjustOutQty", minWidth: 120 },
              { label: "库存调整出库金额", prop: "adjustOutMoney", minWidth: 130 },
              { label: "门店零售数", prop: "shopRetailQty", minWidth: 100 },
              { label: "门店零售金额", prop: "shopRetailMoney", minWidth: 100 },
              { label: "门店销售数", prop: "shopSaleQty", minWidth: 120 },
              { label: "门店销售金额", prop: "shopSaleMoney", minWidth: 130 },
              { label: "采购退货数", prop: "purBackQty", minWidth: 100 },
              { label: "采购退货金额", prop: "purBackMoney", minWidth: 100 },
              { label: "盘亏数", prop: "loseQty", minWidth: 100 },
              { label: "盘亏金额", prop: "loseMoney", minWidth: 100 },
            ],
          },
        ],
      },
      optionsDetail: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'d4edb18a-4616-27b6-9832-70a200444f51',
        body: {},
        title: "商品库存明细",
        tableTitle: "商品库存明细",
        list: [],
        search: [
          { type: "input", label: '仓库', tip:" ", model: "", filter: "storeName", disabled: true},
          { type: "input", label: '商品编码', tip:" ", model: "", filter: "goodsNo", disabled: true},
          { type: "input", label: '商品名称', tip:" ", model: "", filter: "goodsName", disabled: true},
          { type: "input", label: '商品条码', tip:" ", model: "", filter: "barcode", disabled: true},
          { type: "input", label: '商品批号', tip:" ", model: "", filter: "batchNo", disabled: true},
          { type: "input", label: '规格', tip:" ", model: "", filter: "goodsSpec", disabled: true},
          { type: "input", label: '基础单位', tip:" ", model: "", filter: "unitName", disabled: true},
          { type: "input", label: '成本价', tip:" ", model: "", filter: "costPrice", disabled: true},
          { type: "input", label: '期初数', tip:" ", model: "", filter: "firstQty", disabled: true},
          { type: "input", label: '期初金额', tip:" ", model: "", filter: "firstMoney", disabled: true},
          { type: "input", label: '入库数', tip:" ", model: "", filter: "inQty", disabled: true},
          { type: "input", label: '入库金额', tip:" ", model: "", filter: "inMoney", disabled: true},
          { type: "input", label: '出库数', tip:" ", model: "", filter: "outQty", disabled: true},
          { type: "input", label: '出库金额', tip:" ", model: "", filter: "outMoney", disabled: true},
          { type: "input", label: '期末数', tip:" ", model: "", filter: "finalQty", disabled: true},
          { type: "input", label: '期末金额', tip:" ", model: "", filter: "finalMoney", disabled: true},
          { type: "button", tip: "关闭", btnType: "primary", click: "close" },
        ],
        summary: ['firstQty', 'firstMoney', 'inQty', 'inMoney', 'outQty', 'outMoney', 'finalQty', 'finalMoney'],
        columns: [
          { label: "记账时间", prop: "auditTime",fixed: true, minWidth: 120 },
          {
            label: "单据编号",
            prop: "billNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "getBillData",
          },
          { label: "单据日期", prop: "billDate", minWidth: 100 },
          { label: "单据类型", prop: "billTypeName", minWidth: 120 },
          { label: "商品批号", prop: "batchNo", minWidth: 100 },
          { label: "期初数", prop: "firstQty", minWidth: 80 },
          { label: "期初金额", prop: "firstMoney", minWidth: 80 },
          { label: "入库数", prop: "inQty", minWidth: 80 },
          { label: "入库金额", prop: "inMoney", minWidth: 100 },
          { label: "出库数", prop: "outQty", minWidth: 80 },
          { label: "出库金额", prop: "outMoney", minWidth: 100 },
          { label: "期末数", prop: "finalQty", minWidth: 80 },
          { label: "期末金额", prop: "finalMoney", minWidth: 100 },
          { label: "备注", prop: "remark", },
        ],
        
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true
          this.optionsDetail.list = []
        break;
        case "oepnDetail": //获取点击商品信息
          let params = {};
          params.goodsId = row.goodsId;
          //当批号存在时 赋值
          if (row.batchNo) {
            params.batchNo = row.batchNo;
          }
          params.storeId = row.storeId;
          const res = await storeInventoryGoodsDetail(params);
          this.optionsDetail.list = res.data.details
          this.optionsDetail.search = this.optionsDetail.search.map((item) => (
            {
              ...item,
              model: res.data.mainReport[item.filter],
            }
          ))
          this.optionsDetail.loading = false
          this.showList = false
          // this.goodsDetailData = res.data; //商品详情赋值
          // this.openStockQuery = true; //打开商品详情弹出框
          break;
        case "getBillData": // 明细点击
          // let billId = row.billId
          // let billType = row.billType
          // let routerName
          // if(billType === '102') routerName = 'PurchaseInstoreDetail'
          // if(billType === '103') routerName = 'PurchaseBackDetail'
          // if(billType === '105') routerName = 'SaleOutDetail'
          // if(billType === '106') routerName = 'SaleBackDetail'
          // if(billType === '107') routerName = 'storeCheckDetail'
          // if(billType === '108') routerName = 'storeAdjustDetail'
          // if(billType === '109') routerName = 'storeWasteDetail'
          // if(billType === '110') routerName = 'storeMoveDetail'
          // if(billType === '111') routerName = 'storeMoveOutDetail'
          // if(billType === '112') routerName = 'storeMoveInDetail'
          // if(billType === '113') routerName = 'storeCheckFullDetail'
          // if(billType === '114') routerName = 'storeCheckLoseDetail'
          // this.$router.push({
          //   name:routerName,
          //   query: {
          //     billId,
          //     type: 'Update'
          //   }
          // })
          break;
        default:
          break;
      }
    }
  },
};
</script>

<!-- <template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { goodsSalePreview } from '@/api/store/report' //销售报表

export default {
  name: 'jxcList',
  components: { TablePage },
  data () {
    return {
      options: {
        // mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        getListApi: goodsSalePreview,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        labelWidth: "80",
        check: [], // 选中数据
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "input",
            tip:"商品编号、商品名称、仓库编号、仓库名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            prop: 'billDateStr',
            label: '日期',
            minWidth: 155
          },
          {
            prop: 'goodsNo',
            label: '商品编号',
            minWidth: 120,
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 120
          },
          {
            prop: 'barcode',
            label: '商品条码',
            minWidth: 160
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 120
          },
          {
            prop: 'storeNo',
            label: '仓库编号',
            minWidth: 150
          },
          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 150
          },
          {
            prop: 'unitName',
            label: '基本单位',
            minWidth: 150
          },
          {
            prop: 'firstQty',
            label: '期初数',
            minWidth: 150
          },
          {
            prop: 'firstMoney',
            label: '期初金额',
            minWidth: 150
          },
          {
            prop: 'inQty',
            label: '收入数',
            minWidth: 150
          },
          {
            prop: 'inMoney',
            label: '收入金额',
            minWidth: 150
          },
          {
            prop: 'outQty',
            label: '发出数',
            minWidth: 150
          },
          {
            prop: 'outMoney',
            label: '发出金额',
            minWidth: 150
          },
          {
            prop: 'finalQty',
            label: '期末数',
            minWidth: 150
          },
          {
            prop: 'finalMoney',
            label: '期末金额',
            minWidth: 150
          },
          {
            prop: 'purQty',
            label: '采购数',
            minWidth: 150
          },
          {
            prop: 'purUnitPrice',
            label: '采购单价',
            minWidth: 150
          },
          {
            prop: 'purMoney',
            label: '采购金额',
            minWidth: 150
          },
          {
            prop: 'purBackQty',
            label: '采购退货数',
            minWidth: 150
          },
          {
            prop: 'purBackMoney',
            label: '采购退货金额',
            minWidth: 150
          },
          {
            prop: 'saleQty',
            label: '销售数',
            minWidth: 150
          },
          {
            prop: 'saleUnitPrice',
            label: '销售单价',
            minWidth: 150
          },
          {
            prop: 'saleMoney',
            label: '销售金额',
            minWidth: 150
          },
          {
            prop: 'saleBackQty',
            label: '销售退货数',
            minWidth: 150
          },
          {
            prop: 'saleBackMoney',
            label: '销售退货金额',
            minWidth: 150
          },
          {
            prop: 'adjustQty',
            label: '仓库调整数',
            minWidth: 150
          },
          {
            prop: 'adjustMoney',
            label: '调整金额',
            minWidth: 150
          },
          {
            prop: 'wasteQty',
            label: '仓库报损数',
            minWidth: 150
          },
          {
            prop: 'wasteMoney',
            label: '报损金额',
            minWidth: 150
          },
          {
            prop: 'adjustInQty',
            label: '仓库调入数',
            minWidth: 150
          },
          {
            prop: 'adjustInMoney',
            label: '仓库调入金额',
            minWidth: 150
          },
          {
            prop: 'adjustOutQty',
            label: '仓库调出数',
            minWidth: 150
          },
          {
            prop: 'adjustOutMoney',
            label: '调出金额',
            minWidth: 150
          },
          {
            prop: 'fullQty',
            label: '仓库盘盈数',
            minWidth: 150
          },
          {
            prop: 'fullMoney',
            label: '盘盈金额',
            minWidth: 150
          },
          {
            prop: 'loseQty',
            label: '仓库盘亏数',
            minWidth: 150
          },
          {
            prop: 'loseMoney',
            label: '盘亏金额',
            minWidth: 150
          },
          {
            prop: 'produceQty',
            label: '生产领用数',
            minWidth: 150
          },
          {
            prop: 'produceMoney',
            label: '生产领用金额',
            minWidth: 150
          },
          {
            prop: 'produceRefundQty',
            label: '生产退料数',
            minWidth: 150
          },
          {
            prop: 'produceRefundMoney',
            label: '生产退料金额',
            minWidth: 150
          },
        ],
        summary: ["firstQty", "firstMoney", "inQty", "inMoney", "outQty", "outMoney", "finalQty", "finalMoney", "purQty", "purMoney", "purBackQty", "purBackMoney", "saleQty", "saleMoney", "saleBackQty", "saleBackMoney", "adjustQty", "adjustMoney", "wasteQty", "wasteMoney", "adjustInQty", "adjustInMoney", "adjustOutQty", "adjustOutMoney", "fullQty", "fullMoney", "loseQty", "loseMoney", "produceQty", "produceRefundQty", "produceRefundMoney"],
      }
    }
  },
  methods: {
    seniorSearch () {},
  }
}
</script>
<style lang="scss" scoped></style> -->
