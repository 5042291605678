<!-- 组装拆卸 -->
<template>
  <div class="wrap">
    <el-form v-loading="loading">
      <!-- content -->
      <div class="content">
        <el-form
          ref="formOptions"
          class="left"
          :model="options"
          :rules="rules"
          label-width="120px"
        >
          <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
        </el-form>
        <el-form
          ref="formOptionsDetail"
          class="right"
          :model="optionsDetail"
          :rules="rulesDetail"
          label-width="120px"
        >
          <TablePage
            ref="tablePageDetail"
            v-model="optionsDetail"
            @handleEvent="handleEventDetail"
          />
        </el-form>
      </div>
    </el-form>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listMainGoods,
  modifyMainGoods,
  delGoodsRelate,
  addMainGoods,
  listSubGoods,
  addSubGoods,
  modifySubGoods,
} from "@/api/combinStrip/index";

export default {
  name: "room",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      rules: {
        relateGoodsId: [
          { required: true, message: "请选择商品", trigger: ["blur", "change"] },
        ],
        relateUnitId: [
          { required: true, message: "请选择单位", trigger: ["blur", "change"] },
        ],
      },
      rulesDetail: {
        billNo: [
          { required: true, message: "请输入方案编号", trigger: ["blur", "change"] },
        ],
      },
      loading: false,
      optionsIndex: "", // 点击选中的商品 下标
      relateGoodsId: "", // 点击选中的商品id
      relateUnitId: "", //  点击选中的商品单位id
      options: {
        // mutiSelect: true, // 多选
        mouseEvent: true, // 是否鼠标移入移出
        radioSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'f5d8444a-7051-7cfd-a285-85163d39f130',
        tableTitle: "主件列表",
        getListApi: listMainGoods,
        getDataKey: (res) => {
          if (res.rows.length == 0) {
            let arr = [this.optionsObj];
            return { list: arr, total: res.total };
          }
          let arr = JSON.parse(JSON.stringify(res.rows));
          this.options.columns[1].option.showItem = arr.map((item) => ({
            goodsName: item.relateGoodsInfo.goodsName,
            goodsId: item.relateGoodsInfo.goodsId,
          }));
          return { list: res.rows, total: res.total };
        },
        list: [],
        body: {},
        listNo: false, // 序号
        listMouse: true, // 操作序号
        titleMouse: "序号", // 操作序号名称
        check: [], // 选中数据
        tableIndex: "", // 移入的下标
        rowKey: "lineId",
        search: [
          {
            type: "filters",
            tip: "商品编号/商品名称/商品条码",
            model: "",
            filters: [
              { filter: "relateGoodsNos", label: "商品编号" },
              { filter: "relateGoodsNames", label: "商品名称" },
              { filter: "relateBarcodes", label: "商品条码" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            width: 170,
            formatter: (v, row) =>
              row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo.goodsNo : "",
          },
          {
            prop: "relateGoodsId",
            label: "商品名称",
            width: 140,
            type: "remoteSelect",
            click: "clickAccount",
            Width: 200,
            align: "center",
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  remoteBody: { excludeGoodsTypes: [-1] },
                  change: (propValue, row) => {
                    let findIndex = this.options.tableIndex;
                    this.options.list[findIndex].relateGoodsId = row.goodsId;
                    this.options.list[findIndex].relateUnitId = "";
                    this.options.list[findIndex].relateGoodsInfo = row;
                    this.options.list[findIndex].relateUnitInfos = row.units;
                  },
                  showItem: [],
                  tableChange: (propValue, row) => {
                    let findIndex = this.options.tableIndex;
                    this.options.list[findIndex].relateGoodsId = row.check.goodsId;
                    this.options.list[findIndex].relateUnitId = "";
                    this.options.list[findIndex].relateGoodsInfo = row.check;
                    this.options.list[findIndex].relateUnitInfos = row.check.units;
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: {
                          ...this.$dialog({ key: "goods" }),
                          queryParams: { excludeGoodsTypes: [-1] },
                          table: {
                            ...this.$dialog({ key: "goods" }).table,
                            radioSelect: true,
                            mutiSelect: false,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "barcode",
            label: "商品条码",
            minWidth: 120,
            formatter: (v, row) =>
              row.relateGoodsInfo?.barcode ? row.relateGoodsInfo.barcode : "",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            width: 80,
            formatter: (v, row) =>
              row.relateGoodsInfo?.goodsSpec ? row.relateGoodsInfo.goodsSpec : "",
          },
          {
            prop: "relateUnitId",
            label: "单位",
            type: "localSelect",
            width: 80,
            align: "center",
            option: {
              rowDataKey: "relateUnitInfos",
              label: "unitName",
              value: "unitId",
              filterable: true,
              change: (val, row) => {
                let tableIndex = this.options.tableIndex;
                let lineId = this.options.list[tableIndex].lineId;
                if (lineId != "333") {
                  modifyMainGoods({
                    lineId: this.options.list[tableIndex].lineId,
                    relateUnitId: row.unitId,
                  }).then((res) => {
                    this.options.list[tableIndex].relateGoodsInfo.goodsSpec =
                      row.goodsSpec;
                    this.$message.success("修改成功");
                  });
                } else {
                  addMainGoods({
                    relateGoodsId: this.options.list[tableIndex].relateGoodsId,
                    relateUnitId: val,
                  }).then((res) => {
                    this.$refs.tablePage.getList();
                    this.$message.success("添加成功");
                  });
                }
              },
            },
            rules: true,
            disabled: false,
          },
        ],
      },
      optionsDetail: {
        // radioSelect: true,
        mouseEvent: true, // 是否鼠标移入移出
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        // getListApi: listMainGoods,
        // getDataKey: res => {
        //     if (res.rows.length == 0) {
        //         return {list : [
        //             {
        //                 relateGoodsId: '',
        //                 relateUnitInfos: [],
        //                 relateGoodsInfo: {
        //                     goodsName: '',
        //                     goodsId: '',
        //                     goodsNo: '',
        //                 }
        //             }
        //         ], total: res.total}
        //     }
        //     let arr = JSON.parse(JSON.stringify(res.rows))
        //     this.options.columns[1].option.showItem = arr.map(item => ({
        //         goodsName: item.relateGoodsInfo.goodsName,
        //         goodsId: item.relateGoodsInfo.goodsId,
        //     }))
        //     return {list : res.rows, total: res.total}
        // },
        list: [],
        body: {},
        listNo: false, // 序号
        listMouse: true, // 操作序号
        titleMouse: "序号", // 操作序号名称
        title: "",
        tableTitle: "子件列表",
        check: [], // 选中数据
        tableIndex: "", // 移入的下标
        // rowKey: "lineId",
        search: [{ type: "button", tip: "重置", click: "reset" }],
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            width: 170,
            formatter: (v, row) => (row.goodsInfo?.goodsNo ? row.goodsInfo.goodsNo : ""),
          },
          {
            prop: "goodsId",
            label: "商品名称",
            type: "remoteSelect",
            click: "clickAccount",
            width: 200,
            align: "center",
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  remoteBody: { excludeGoodsTypes: [-1] },
                  change: async (propValue, row) => {
                    // let findIndex = this.optionsDetail.tableIndex
                    // this.optionsDetail.list[findIndex].goodsId = row.goodsId
                    // this.optionsDetail.list[findIndex].unitId = ''
                    // this.optionsDetail.list[findIndex].goodsInfo = row
                    // this.optionsDetail.list[findIndex].unitInfos = row.units
                    // this.optionsDetail.list[findIndex].purPrice = row.purPrice
                    // this.optionsDetail.list[findIndex].salePrice = row.salePrice
                    let params = [
                      {
                        relateGoodsId: this.relateGoodsId,
                        relateUnitId: this.relateUnitId,
                        goodsId: row.goodsId,
                        unitId: row.unitId,
                        unitQty: row.unitQty,
                        purPrice: row.purPrice,
                        salePrice: row.salePrice,
                        unitQty: 1,
                      },
                    ];
                    try {
                      await addSubGoods(params);
                      this.$message.success("添加成功");
                      this.listSubGoodsFun();
                    } catch (error) {
                      this.listSubGoodsFun();
                    }
                  },
                  showItem: [],
                  tableChange: async (propValue, row) => {
                    let params = row.check.map((item) => ({
                      relateGoodsId: this.relateGoodsId,
                      relateUnitId: this.relateUnitId,
                      goodsId: item.goodsId,
                      unitId: item.unitId,
                      unitQty: item.unitQty,
                      purPrice: item.purPrice,
                      salePrice: item.salePrice,
                      unitQty: 1,
                    }));
                    try {
                      await addSubGoods(params);
                      this.$message.success("添加成功");
                      this.listSubGoodsFun();
                    } catch (error) {
                      this.listSubGoodsFun();
                    }
                    // let findIndex = this.optionsDetail.tableIndex
                    // this.optionsDetail.list[findIndex].goodsId = row.check.goodsId
                    // this.optionsDetail.list[findIndex].unitId = ''
                    // this.optionsDetail.list[findIndex].goodsInfo = row.check
                    // this.optionsDetail.list[findIndex].unitInfos = row.check.units
                    // this.optionsDetail.list[findIndex].purPrice = row.check.purPrice
                    // this.optionsDetail.list[findIndex].salePrice = row.check.salePrice
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: {
                          ...this.$dialog({ key: "goods" }),
                          queryParams: { excludeGoodsTypes: [-1] },
                          table: {
                            ...this.$dialog({ key: "goods" }).table,
                            // radioSelect:true,
                            mutiSelect: true,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
          },
          {
            prop: "barcode",
            label: "商品条码",
            width: 120,
            formatter: (v, row) => (row.goodsInfo?.barcode ? row.goodsInfo.barcode : ""),
          },
          {
            prop: "goodsSpec",
            label: "规格",
            width: 80,
            formatter: (v, row) =>
              row.goodsInfo?.goodsSpec ? row.goodsInfo.goodsSpec : "",
          },
          {
            prop: "unitId",
            label: "单位",
            type: "localSelect",
            width: 80,
            align: "center",
            option: {
              rowDataKey: "unitInfos",
              label: "unitName",
              value: "unitId",
              filterable: true,
              change: (val, row) => {
                let tableIndex = this.optionsDetail.tableIndex;
                this.numUnit(row, tableIndex);
              },
            },
            disabled: false,
          },
          {
            prop: "unitQty",
            label: "数量",
            width: 120,
            type: "input",
            inputNum: this.$store.state.permission.inputConfig
              .system_BUSINESS_DATA_CONFIG_11,
            blurInput: "blurInput",
            align: "center",
          },
          {
            prop: "purPrice",
            label: "进价",
            width: 120,
            align: "center",
          },
          {
            prop: "salePrice",
            label: "售价",
            width: 120,
            align: "center",
          },
        ],
      },
      optionsDetailObj: {
        lineId: "333",
        goodsId: "",
        unitInfos: [],
        goodsInfo: { goodsName: "", goodsId: "", goodsNo: "" },
        unitQty: "1",
        purPrice: "",
        salePrice: "",
      },
      optionsObj: {
        lineId: "333",
        relateGoodsId: "",
        relateUnitId: "",
        relateGoodsInfo: {},
        relateUnitInfos: [],
      },
    };
  },
  methods: {
    listSubGoodsFun() {
      let that = this;
      listSubGoods({ relateGoodsId: this.relateGoodsId, relateUnitId: this.relateUnitId })
        .then((res) => {
          let arr = [that.optionsDetailObj];
          that.optionsDetail.list = res.rows?.length > 0 ? res.rows : arr;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "rowClick":
          if (selectData.length == 0) {
            return (this.optionsDetail.list = []);
          }
          let index = this.options.list.indexOf(row);
          this.relateGoodsId = this.options.list[index].relateGoodsId;
          this.relateUnitId = this.options.list[index].relateUnitId;
          if (
            this.relateGoodsId == null ||
            this.relateGoodsId == undefined ||
            this.relateGoodsId == ""
          ) {
            return this.$message.error("请选择商品");
          }
          if (
            this.relateUnitId == null ||
            this.relateUnitId == undefined ||
            this.relateUnitId === ""
          ) {
            return this.$message.error("请选择单位");
          }
          this.loading = true;
          this.listSubGoodsFun();
          break;
        case "mouseEvent":
          if (row.type == "del") {
            if (this.options.list.length == 1 && !this.options.list[0].lineId) return;
            if (this.options.list[row.index].lineId == 333) {
              this.options.list.splice(row.index, 1);
              if (this.options.list.length == 0) {
                this.options.list.push(this.optionsObj);
              }
              return;
            }
            await this.$modal.confirm(`是否确认删除商品`);
            delGoodsRelate({ lineId: this.options.list[row.index].lineId }).then(
              (res) => {
                this.$refs.tablePage.getList();
                this.$message.success("删除成功");
                if (this.options.list.length == 0) {
                  this.options.list.push(this.optionsObj);
                }
              }
            );
          } else {
            let index = this.options.list.length;
            let lineId = this.options.list[index - 1].lineId;
            // if (lineId == '333') return this.$message.warning('请完成单位选择')
            // this.options.list.push({
            //     lineId: '333',
            //     relateGoodsId: '',
            //     relateUnitId: '',
            //     relateGoodsInfo: {},
            //     relateUnitInfos: [],
            // })
            this.options.list.push(this.optionsObj);
          }
          break;
        default:
          break;
      }
    },
    async handleEventDetail(type, row) {
      switch (type) {
        case "blurInput":
          this.numUnit(row, row.index);
          break;
        case "mouseEvent":
          if (row.type == "del") {
            if (this.optionsDetail.list.length == 1 && !this.optionsDetail.list[0].lineId)
              return;
            if (this.optionsDetail.list[row.index].lineId == 333) {
              this.optionsDetail.list.splice(row.index, 1);
              if (this.optionsDetail.list.length == 0) {
                this.optionsDetail.list.push(this.optionsDetailObj);
              }
              return;
            }
            await this.$modal.confirm(`是否确认删除商品`);
            delGoodsRelate({ lineId: this.optionsDetail.list[row.index].lineId }).then(
              (res) => {
                this.optionsDetail.list.splice(row.index, 1);
                this.$message.success("删除成功");
                if (this.optionsDetail.list.length == 0) {
                  this.optionsDetail.list.push(this.optionsDetailObj);
                }
              }
            );
          } else {
            let index = this.optionsDetail.list.length;
            let lineId = this.optionsDetail.list[index - 1].lineId;
            if (lineId == "333") return this.$message.warning("请完成单位选择");
            this.optionsDetail.list.push(this.optionsDetailObj);
          }
          break;
        default:
          break;
      }
    },
    numUnit(row, tableIndex) {
      let indexRow = this.optionsDetail.list[tableIndex];
      let lineId = this.optionsDetail.list[tableIndex].lineId;
      if (lineId != "333") {
        let params = {
          lineId: lineId,
          unitId: indexRow.unitId,
          unitQty: indexRow.unitQty,
          purPrice: indexRow.purPrice,
          salePrice: indexRow.salePrice,
        };
        modifySubGoods(params).then((res) => {
          let unitInfosData = indexRow.unitInfos.filter(
            (v) => v.unitId == indexRow.unitId
          );
          this.optionsDetail.list[tableIndex].goodsInfo.goodsSpec =
            unitInfosData[0].goodsSpec;
          // this.optionsDetail.list[tableIndex].salePrice = unitInfosData[0].salePrice
          // this.optionsDetail.list[tableIndex].purPrice = unitInfosData[0].purPrice
          this.$message.success("修改成功");
        });
      } else {
        let params = {
          relateGoodsId: this.relateGoodsId,
          relateUnitId: this.relateUnitId,
          goodsId: indexRow.goodsId,
          unitId: indexRow.unitId,
          unitQty: indexRow.unitQty,
          purPrice: indexRow.purPrice,
          salePrice: indexRow.salePrice,
        };
        addSubGoods([params]).then((res) => {
          this.$message.success("添加成功");
          this.listSubGoodsFun();
          // this.optionsDetail.list[tableIndex].lineId = res.data.lineId
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  .left {
    width: 45%;
  }
  .right {
    width: 53%;
  }
}
.flex1 {
  flex: 1;
  width: 49%;
}
</style>
