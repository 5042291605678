<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
    <!-- .@handleEvent="handleEvent" -->
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { alertListAPI, allAlertListAPI } from '@/api/store/analyse/stockalert' //库存预警

export default {
  name: "saleRank",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'18816d57-4a76-7593-e7fa-b11851a31656',
        body: {},
        title: "库存预警查询表",
        labelWidth: "80",
        search: [
          this.$select({
            key: "listStore",
            option: { hide: () => ["store"].includes(this.options.tabColumnType), option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "仓库安全库存警戒分析",
            defaultBody: { searchType: "store" },
            type: "store",
            getListApi: alertListAPI,
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "avgConsume",
                label: "平均日消耗",
                minWidth: 150,
              },
              {
                prop: "safeMaxQty",
                label: "最高库存",
                minWidth: 150,
              },
              {
                prop: "safeMinQty",
                label: "最低库存",
                minWidth: 150,
              },
              {
                prop: "currentInventory",
                label: "当前库存",
                minWidth: 150,
              },
              {
                prop: "exceedSafeMinQty",
                label: "超低警戒数",
                minWidth: 150,
              },
              {
                prop: "exceedSafeMaxQty",
                label: "超高警戒数",
                minWidth: 150,
              },
            ],
          },
          {
            title: "总安全库存警戒分析",
            defaultBody: { searchType: "summary" },
            type: "summary",
            getListApi: allAlertListAPI,
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基础单位",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 150,
              },
              {
                prop: "safeMinQty",
                label: "最低库存",
                minWidth: 150,
              },
              {
                prop: "safeMaxQty",
                label: "最高库存",
                minWidth: 150,
              },
              {
                prop: "currentInventory",
                label: "当前库存",
                minWidth: 150,
              },
              {
                prop: "exceedSafeMinQty",
                label: "超低警戒数",
                minWidth: 150,
              },
              {
                prop: "exceedSafeMaxQty",
                label: "超高警戒分析数",
                minWidth: 150,
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
