<template>
  <!-- 商品库存明细详情页 -->
  <div class="stock-query-detail">
    <el-dialog
      title="商品库存明细"
      :visible.sync="openStockQuery"
      width="80%"
      :before-close="handleClose"
      v-dialogDrag="true"
      :modal="false"
    >
      <div class="TopRegion">
        <el-form label-width="80px" :model="goodsDetailData.mainReport">
          <el-form-item
            :label="item.label"
            v-for="item in TopLabel"
            :key="item.label"
            :prop="item.prop"
          >
            <el-input
              disabled
              v-model="goodsDetailData.mainReport[`${item.prop}`]"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttom">
        <allTable
          :tableData="goodsDetailData.details"
          :columnLabel="columnLabel"
          :columnWidth="columnWidth"
          :firstLabel="firstLabel"
          :NoLabel="NoLabel"
          :tableHeight="tableHeight"
          @getRowData="getBillData"
        ></allTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import allTable from '../../components/all-table.vue' //表格组件
export default {
  name: 'StockQueryDetail',
  components: { allTable },
  props: {
    openStockQuery: {
      //弹出框开关
      type: Boolean,
      default: false
    },
    goodsDetailData: {
      //商品库存明细数据
      type: Object
    }
  },
  data () {
    return {
      TopLabel: [], //表头字段
      //表格第一列字段
      firstLabel: [],
      //表格编号字段
      NoLabel: [],
      //表格列字段
      columnLabel: [],
      //表格高度
      tableHeight: '600',
      //表格列宽度
      columnWidth: '160'
    }
  },
  watch: {
    //侦听弹出框开关是否开启
    openStockQuery: {
      handler (val) {
        if (val) {
          //当开关时开启状态,添加表头和表格的字段
          this.TopLabel = [
            //表头字段
            { label: '仓库', prop: 'storeName' },
            { label: '商品编码', prop: 'goodsNo' },
            { label: '商品名称', prop: 'goodsName' },
            { label: '商品条码', prop: 'barcode' },
            { label: '商品批号', prop: 'batchNo' },
            { label: '规格', prop: 'goodsSpec' },
            { label: '基础单位', prop: 'unitName' },
            { label: '成本价', prop: 'costPrice' },
            { label: '标准进价', prop: 'purPrice'},
            { label: '标准售价', prop: 'salePrice'},
            { label: '期初数', prop: 'firstQty' },
            { label: '期初金额', prop: 'firstMoney' },
            { label: '入库数', prop: 'inQty' },
            { label: '入库金额', prop: 'inMoney' },
            { label: '出库数', prop: 'outQty' },
            { label: '出库金额', prop: 'outMoney' },
            { label: '期末数', prop: 'finalQty' },
            { label: '期末金额', prop: 'finalMoney' }
          ]
          //表格第一列字段
          this.firstLabel = [{ label: '记账时间', prop: 'auditTime' }]
          //表格编号字段
          this.NoLabel = ['单据编号', 'billNo']
          //表格列字段
          this.columnLabel = [
            { label: '单据日期', prop: 'billDate' },
            { label: '单据类型', prop: 'billTypeName' },
            { label: '商品批号', prop: 'batchNo' },
            // { label: '标准进价', prop: 'purPrice'},
            // { label: '标准售价', prop: 'salePrice'},
            { label: '期初数', prop: 'firstQty' },
            { label: '期初金额', prop: 'firstMoney' },
            { label: '入库数', prop: 'inQty' },
            { label: '入库金额', prop: 'inMoney' },
            { label: '出库数', prop: 'outQty' },
            { label: '出库金额', prop: 'outMoney' },
            { label: '期末数', prop: 'finalQty' },
            { label: '期末金额', prop: 'finalMoney' },
            { label: '备注', prop: '' }
          ]
        } else {
          //当开关时关闭状态,初始化表头和表格的字段
          this.TopLabel = [] //表头字段
          //表格第一列字段
          this.firstLabel = []
          //表格编号字段
          this.NoLabel = []
          //表格列字段
          this.columnLabel = []
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClose () {
      //对话框关闭的回调
      this.$emit('update:openStockQuery', false)
    },
    //获取点击单据信息 跳转单据详情页
    getBillData (data) {
      console.log(data)
      let billId = data.billId
      let billType = data.billType
      let routerName
      if (billType === '102') routerName = 'PurchaseInstoreDetail'
      if (billType === '103') routerName = 'PurchaseBackDetail'
      if (billType === '105') routerName = 'SaleOutDetail'
      if (billType === '106') routerName = 'SaleBackDetail'
      if (billType === '107') routerName = 'storeCheckDetail'
      if (billType === '108') routerName = 'storeAdjustDetail'
      if (billType === '109') routerName = 'storeWasteDetail'
      if (billType === '110') routerName = 'storeMoveDetail'
      if (billType === '111') routerName = 'storeMoveOutDetail'
      if (billType === '112') routerName = 'storeMoveInDetail'
      if (billType === '113') routerName = 'storeCheckFullDetail'
      if (billType === '114') routerName = 'storeCheckLoseDetail'
      this.$router.push({
        name: routerName,
        query: {
          billId,
          type: 'Update'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-query-detail {
  //顶部区域
  .TopRegion {
    width: 100%;
    .el-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .el-form-item {
        .el-input {
          width: 160px;
        }
      }
    }
  }
}
</style>
