<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { listMonthSettle, applyMonthSettle } from '@/api/store/monthSettle/operate'
import { listStore } from '@/api/goods/store' //仓库接口
import moment from 'moment'
export default {
  name: 'qualityalert',
  components: { TablePage },
  data () {
    return {
      options: {
        uuid:'609d51dc-8d13-dd55-97ac-8d7146cb56be',
      },
      list: [],
    }
  },
  methods: {
    filterTime (currentDate) {
      const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
      return {
        Year: nextMonth.getFullYear(),
        Month: nextMonth.getMonth()+ 1,
        day: nextMonth.getDate()
      }
    },
    func (propValue) {
      let list = this.options.list.filter((v)=> v.storeInfo.storeId == propValue).map((item) => ({
        ...item,
        getTime: new Date(`${item.begDate} 00:00:00`).getTime(),
      }))
      if (list.length) {
        let maxObject = list.reduce((maxObj, obj) => {
          return maxObj.getTime > obj.getTime ? maxObj : obj;
        });
        console.log(maxObject, 'maxObject');
        let currentDate = new Date(maxObject.begDate);
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
        const year = nextMonth.getFullYear()
        const month = nextMonth.getMonth()+ 1 < 10? nextMonth.getMonth()+ 1 : nextMonth.getMonth()+ 1 
        let d = new Date(`${year}-${month}`);
        d.setMonth(d.getMonth()+1);
        d.setDate(d.getDate()-1);
        this.options.search[1].model= [`${year}-${month}-01`, `${year}-${month}-${d.getDate()}`]
        this.options.search[0].model = `${year}-${month}`
      } else {
        const time = new Date();
        const year = time.getFullYear();
        const month = time.getMonth() < 10 ? `0${time.getMonth() + 1}` : time.getMonth();
        let d = new Date(`${year}-${month}`);
        d.setMonth(d.getMonth()+1);
        d.setDate(d.getDate()-1);
        this.options.search[0].model = `${year}-${month}`
        this.options.search[1].model= [`${year}-${month}-01`, `${year}-${month}-${d.getDate()}`]
      }
    },
    async handleEvent (type, row) {
      console.log(type, row, 'type, row');
      switch (type) {
        case 'month':
          const year = row.slice(0, 4);
          const month = row.slice(5, 7);
          let d = new Date(row);
          d.setMonth(d.getMonth()+1);
          d.setDate(d.getDate()-1);
          this.options.search[1].model= [`${year}-${month}-01`, `${year}-${month}-${d.getDate()}`]
          break
        case 'handleGoods':
        await this.$confirm(`是否确认要月结`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          applyMonthSettle({
            month: moment(this.options.search[0].model).format('yyyyMM'),
            begDate: this.options.search[1].model[0],
            endDate: this.options.search[1].model[1],
            storeId: this.options.search[2].model,
          }).then((res) => {
            this.$message.success('执行成功')
            //新增
            this.$refs.tablePage.getList();
          })
          break
        default:
          break
      }
    }
  },
  async mounted () {
    this.list = await listStore({
      pageNum: 1,
      pageSize: 1000,
    })
    const array = this.list.rows.filter((v)=> v.isProduceFinishProductStore)
    this.options =  {
      // mutiSelect: true, // 多选
      listNo: true, // 序号
      loading: true,
      pagination: {
        total: 0,
        page: 1,
        size: 15
      },
      getListApi: listMonthSettle,
      body: {}, // 请求内容
      check: [], // 选中数据
      rowKey: "bookDetailId",
      labelWidth: "120",
      title: '月结',
      search: [
        {
          type: 'month',
          label: '月份',
          model: '',
          filterFnc: (a) => moment(a).format('yyyyMM'),
        },
        {
          label: "日期",
          type: "daterange",
          model: '',
          option: {
            disabled: true
          },
          filterFnc: ([a, b] = []) => ({ begDate: a, endDate: b }),
        },
        this.$select({ 
          key: "listStore",
          option: {
            model: array.length? array[0].storeId : this.list.rows[0].storeId,
            option: {
              change: (propValue, row) => {
                console.log(propValue, row, 'change');
                console.log(this.options.list, 'this.options.list');
                this.func(propValue)
              },
            }
          }
        }),
        { type: 'button', tip: '刷新', btnType: '', click: 'refresh' },
        { type: 'button', tip: '执行', btnType: 'primary', click: 'handleGoods' },
      ],
      columns: [
        {
          prop: 'storeName',
          label: '仓库',
          formatter: (v, row) => row.storeInfo?.storeName?  row.storeInfo?.storeName: '',
          minWidth: 80
        },
        {
          prop: 'month',
          label: '月份',
          minWidth: 80
        },
        {
          prop: 'begDate',
          label: '开始日期',
          minWidth: 100
        },
        {
          prop: 'endDate',
          label: '结束日期',
          minWidth: 100
        },
        {
          prop: 'statusName',
          label: '状态',
          minWidth: 80
        },
        {
          prop: 'finishDate',
          label: '执行日期',
          minWidth: 80
        },
      ],
      list: []
    }
    this.func(array.length? array[0].storeId : this.list.rows[0].storeId)
  }
}
</script>
<style lang="scss" scoped></style>
