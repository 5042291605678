<template>
  <!-- 仓库报损单明细 -->
  <div class="check-detail">
    <StoreDetail :billType="billType" :uuid="uuid" ref="storeDetail" :orderInfo='orderInfo'>
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <!-- <div class="searchItem">
          <el-form-item label="仓库名称" prop="storeId">
            <SelectFrame
              ref="selectStore"
              v-model="scoped.form.storeName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'storeId', 'form')"
              @clickAdd="OpenStoreAdd = true"
              @click="OpenStore = true"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div> -->
        <div class="searchItem">
          <el-form-item label="报损原因" prop="scrapTypeId">
            <el-select v-model="scoped.form.scrapTypeId" placeholder="请选择报损原因" clearable :disabled="scoped.disabled">
              <el-option v-for="dict in busiDicts.shop_scrap_type" :key="dict.dictValue" :label="dict.dictLabel"
                :value="dict.dictValue" />
            </el-select>
          </el-form-item>
        </div>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import StoreDetail from '@/views/components/bill/storeDetail.vue'
import business from '@/utils/mixin/business'
import selectStore from '@/views/components/selectStore' //选择仓库组件
import storeAdd from '@/views/components/selectStore/storeAdd.vue' //新增仓库组件
export default {
  name: 'storeWasteDetail',
  mixins: [business],
  components: { StoreDetail, selectStore, storeAdd },
  data() {
    return {
      orderInfo: undefined,
      billType: '130103',
      OpenStoreAdd: false,
      uuid:'a02b6f2e-1f3e-d34c-1b3e-5a0d291e28a7',
      OpenStore: false
    }
  },
  created() {
    this.business('shop_scrap_type')
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type == 'Add' && vm.$route.query.orderInfo) {
        vm.orderInfo = JSON.parse(vm.$route.query.orderInfo)
      }
    });
  },
  methods: {
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(
        event,
        'form',
        'storeId',
        'selectStore',
        true
      )
    }
  }
}
</script>
