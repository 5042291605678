var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "move-diff-detail" },
    [
      _c("StoreDetail", {
        ref: "storeDetail",
        attrs: { billType: _vm.billType, uuid: _vm.uuid, tableSpecialCount: 4 },
        scopedSlots: _vm._u([
          {
            key: "selectOneArea",
            fn: function (scoped) {
              return [
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            disabled: scoped.disabled,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: scoped.form.billDate,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "billDate", $$v)
                            },
                            expression: "scoped.form.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发货仓库", prop: "outStoreId" } },
                      [
                        _c("SelectFrame", {
                          ref: "selectOutStore",
                          attrs: {
                            tableData: scoped.storeData,
                            disabled: scoped.disabled,
                            normalizer: scoped.normalizerStore,
                          },
                          on: {
                            getPitchId: function ($event) {
                              return scoped.getDiyselectId(
                                $event,
                                "outStoreId",
                                "form"
                              )
                            },
                            clickAdd: function ($event) {
                              return _vm.handleStore("add", "outStoreId")
                            },
                            click: function ($event) {
                              return _vm.handleStore("update", "outStoreId")
                            },
                          },
                          model: {
                            value: scoped.form.outStoreName,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "outStoreName", $$v)
                            },
                            expression: "scoped.form.outStoreName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货仓库", prop: "inStoreId" } },
                      [
                        _c("SelectFrame", {
                          ref: "selectInStore",
                          attrs: {
                            tableData: scoped.storeData,
                            disabled: scoped.disabled,
                            normalizer: scoped.normalizerStore,
                          },
                          on: {
                            getPitchId: function ($event) {
                              return scoped.getDiyselectId(
                                $event,
                                "inStoreId",
                                "form"
                              )
                            },
                            clickAdd: function ($event) {
                              return _vm.handleStore("add", "inStoreId")
                            },
                            click: function ($event) {
                              return _vm.handleStore("update", "inStoreId")
                            },
                          },
                          model: {
                            value: scoped.form.inStoreName,
                            callback: function ($$v) {
                              _vm.$set(scoped.form, "inStoreName", $$v)
                            },
                            expression: "scoped.form.inStoreName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "tableSpecial",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "已发货数量",
                    align: "center",
                    prop: "basUnitAdjustQty",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "已收货数量",
                    align: "center",
                    prop: "basUnitAdjustMoney",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "差异数量",
                    align: "center",
                    prop: "basUnitLoseQty",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "收货数量",
                    align: "center",
                    prop: "basUnitLoseMoney",
                    "show-overflow-tooltip": "",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selectStore", {
        attrs: { OpenStore: _vm.OpenStore },
        on: {
          "update:OpenStore": function ($event) {
            _vm.OpenStore = $event
          },
          "update:open-store": function ($event) {
            _vm.OpenStore = $event
          },
          getStore: _vm.getStore,
        },
      }),
      _c("storeAdd", {
        attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
        on: {
          "update:OpenStoreAdd": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          "update:open-store-add": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          getStore: _vm.getStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }