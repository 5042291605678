<template>
  <!-- 仓库调拨单明细 -->
  <div class="move-detail">
    <StoreDetail :billType="billType" ref="storeDetail" :uuid="uuid" :orderInfo='orderInfo'>
      <!-- 插槽 -->
      <div>
        <!-- <template #selectOneArea="scoped"> -->
        <!-- <div class="searchItem">
          <el-form-item label="发货仓库" prop="outStoreId">
            <SelectFrame
              ref="selectOutStore"
              v-model="scoped.form.outStoreName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'outStoreId', 'form')"
              @clickAdd="handleStore('add', 'outStoreId')"
              @click="handleStore('update', 'outStoreId')"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div>
        <div class="searchItem">
          <el-form-item label="收货仓库" prop="inStoreId">
            <SelectFrame
              ref="selectInStore"
              v-model="scoped.form.inStoreName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event, 'inStoreId', 'form')"
              @clickAdd="handleStore('add', 'inStoreId')"
              @click="handleStore('update', 'inStoreId')"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div> -->
        <!-- 选择仓库对话框 -->
        <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
        <!-- 新增仓库对话框 -->
        <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
      </div>
      <!-- </template> -->
    </StoreDetail>
  </div>
</template>

<script>
import StoreDetail from '@/views/components/bill/storeDetail.vue'
import selectStore from '@/views/components/selectStore' //选择仓库组件
import storeAdd from '@/views/components/selectStore/storeAdd.vue' //新增仓库组件
export default {
  name: 'storeMoveDetail',
  components: { StoreDetail, selectStore, storeAdd },
  data() {
    return {
      orderInfo: undefined,
      billType: '130104',
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      uuid:'e3b46cba-6fa9-111c-9af7-dd8ca8bc7b53',
      //仓库类型
      storeType: undefined,
      //搜索框ref
      ref: undefined
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type == 'Add' && vm.$route.query.orderInfo) {
        vm.orderInfo = JSON.parse(vm.$route.query.orderInfo)
      }
    });
  },
  methods: {
    //新增仓库/选择仓库
    handleStore(type, name) {
      if (type === 'add') {
        this.OpenStoreAdd = true
      } else {
        this.OpenStore = true
      }
      if (name === 'outStoreId') {
        this.storeType = name
        this.ref = 'selectOutStore'
      } else {
        this.storeType = name
        this.ref = 'selectInStore'
      }
    },
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(
        event,
        'form',
        this.storeType,
        this.ref,
        true
      )
    }
  }
}
</script>
