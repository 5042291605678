<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { httpListStoreBookDetail, sumListStoreBookDetail } from "@/api/storeReport"; //接口api
import { listGoods } from "@/api/goods/goods"; //商品列表
import { listCategory } from "@/api/goods/category";
import { allStoreList } from "@/api/system/store"; //仓库
const typesList = ['100201','110102','110103','120102','120103','130102','130103','130104','130105','130106','130107','130108','140303','140304','140305','140306','140307','140308','140310','140311','170202','170203','170205','100201',]
export default {
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        uuid:'47370b6d-4a66-8ef1-e796-e49d73c80c0c',
        getListApi: httpListStoreBookDetail,
        getSummaryApi: sumListStoreBookDetail,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        labelWidth: "100",
        exportOption: {
          show: true,
          exportApi: 'exportListStoreBookOutInDetail',
          exportName: '商品出入库明细表',
          timeout: 60*5*1000
        },
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/单据编号/商品编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "goodsNos", label: "商品编号" },
            ],
          },
          {
            label: "记账日期",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b }),
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "billTypeList",
            option: { seniorSearch: true, option: { multiple: true, dataKey:(res)=> res.data.filter(x=> typesList.includes(x.dictValue) )} },
          }),
          this.$select({
            key: "listBillPostTypes",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "inOutStoreList",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          { label: "单据编号", prop: "billNo", minWidth: 150 },
          { label: "单据日期", prop: "billDate", minWidth: 150 },
          { label: "仓库", prop: "storeName", minWidth: 150 },
          { label: "商品编号", prop: "goodsNo", minWidth: 150 },
          { label: "商品名称", prop: "goodsName", minWidth: 150 },
          { label: "商品条码", prop: "barcode", minWidth: 150 },
          { label: "商品类别", prop: "categoryName", minWidth: 150 },
          { label: "规格", prop: "goodsSpec", minWidth: 150 },
          { label: "基础单位", prop: "unitName", minWidth: 150 },
          { label: "入库数", prop: "inQty", minWidth: 150 },
          { label: "入库金额", prop: "inMoney", minWidth: 150 },
          { label: "出库数", prop: "outQty", minWidth: 150 },
          { label: "出库金额", prop: "outMoney", minWidth: 150 },
          { label: "期末数", prop: "finalQty", minWidth: 150 },
          { label: "期末金额", prop: "finalMoney", minWidth: 150 },
          { label: "记账日期", prop: "bookDate", minWidth: 150 },
          { label: "单据类型", prop: "billTypeName", minWidth: 150 },
          { label: "过账类型", prop: "billPostType", minWidth: 150 },
          // { label: "摘要", prop: "" },
        ],
        list: [],
        summary: ["inQty", "inMoney", "outQty", "outMoney", "finalQty", "finalMoney"],
      },
    };
  },
  methods: {
    handleEvent() {},
  },
};
</script>
<style lang="scss" scoped></style>
